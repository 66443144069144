import React from "react"
import { Container } from "react-bootstrap"
import "./TeamLandingBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const TeamLandingBanner = ({ pageData, moduleData }) => {
  return (
    <div className="team-landing-wrapper">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="team-landing-content">
          <h1>{moduleData?.title}</h1>
          <p className="description">
            <ContentModule Content={moduleData?.content?.data?.content} />
          </p>
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default TeamLandingBanner
