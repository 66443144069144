import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import { teamURL } from "../../lib/urls"
import { capitalFirst } from "../Common/utils"
import InnerPagination from "../InnerPagination/InnerPagination"

import ScrollAnimation from "react-animate-on-scroll"
import "./TeamListing.scss"
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const TeamListing = ({ teamsData, strapi_id, teamCategories, searchValue }) => {
  let querySelectedOption
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    const department = result?.get("department")
    querySelectedOption = {
      label: capitalFirst(department?.replace(/-/g, " ")),
      value: department,
    }
  }

  let querySearch
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    let search = result?.get("name")
    querySearch = search?.replace("-"," ")
  }
  var allCategoryOption = {
    label: "All Departments",
    value: "all",
  }

  const [searchedValue, setSearchedValue] = useState(
    searchValue ? searchValue : ""
  )

  const [search, setSearch] = useState(querySearch ? querySearch : "")

  const [selectedOption, setSelectedOption] = useState(
    querySelectedOption?.label && querySelectedOption?.label !== "All"
      ? querySelectedOption
      : allCategoryOption
  )
  // search results from home page team module
  const searchedData = teamsData.filter((data, index) => {
    if (searchedValue) {
      if (
        data.node?.name
          ?.toLowerCase()
          ?.includes(searchedValue?.toLowerCase()) ||
        data.node?.choose_areas?.some(c =>
          c.title?.toLowerCase().includes(searchedValue?.toLowerCase())
        )
      ) {
        return data
      }
      if (data?.node?.team_member?.length > 0) {
        return data.node.team_member?.some(
          data =>
            data.designation?.toLowerCase() === searchedValue?.toLowerCase()
        )
      }
    }
  })

  let filterdata = []
  if (
    querySelectedOption?.label &&
    querySelectedOption?.label !== "All" &&
    querySearch
  ) {
    let filteredTeams = []
    teamsData.filter(team => {
      if (team.node.team_member?.length > 0) {
        team.node.team_member?.filter(member => {
          if (
            member?.designation
              ?.replace(/ /g, "-")
              .toLowerCase()
              ?.includes(
                querySelectedOption?.value?.replace(/ /g, "-").toLowerCase()
              )
          ) {
            filteredTeams.push(team)
          }
        })
      }
    })

    let teamMember = filteredTeams.filter(
      (team, index) =>
        team.node?.name?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
        team.node?.choose_areas?.some(c =>
          c.title?.toLowerCase().includes(querySearch?.toLowerCase())
        )
    )

    filterdata = querySearch ? teamMember : filteredTeams
  } else if (
    (querySearch && querySelectedOption?.label === "All") ||
    (querySearch && !querySelectedOption?.value)
  ) {
    let teamMember = teamsData.filter(
      (team, index) =>
        team.node?.name?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
        team.node?.choose_areas?.some(c =>
          c.title?.toLowerCase().includes(querySearch?.toLowerCase())
        )
    )
    filterdata = teamMember
  } else if (
    !querySearch &&
    querySelectedOption?.label !== "All" &&
    querySelectedOption?.value !== null
  ) {
    let filteredTeams = []
    teamsData.filter(team => {
      if (team.node.team_member?.length > 0) {
        team.node.team_member?.filter(member => {
          if (
            member?.designation
              ?.replace(/ /g, "-")
              .toLowerCase()
              ?.includes(
                querySelectedOption?.value?.replace(/ /g, "-").toLowerCase()
              )
          ) {
            filteredTeams.push(team)
          }
        })
      }
    })
    filterdata = filteredTeams
  } else {
    filterdata = teamsData
  }
  const [selectedTeams, setSelectedTeams] = useState(
    searchedValue ? searchedData : filterdata ? filterdata : teamsData
  )

  let categoryOption = []
  categoryOption.push(allCategoryOption)

  teamCategories.forEach(element => {
    if (element.node.designation) {
      categoryOption.push({
        label: element.node.designation,
        value: element.node.designation?.replace(/ /g, "-").toLowerCase(),
      })
    }
  })

  const label = categoryOption.map(({ value }) => value)
  let categoriesOptionList = categoryOption.filter(
    ({ value }, index) => !label.includes(value, index + 1)
  )

   // for pagination
   const itemsPerPage = 12
   const { currentItems, currentPage, setCurrentPage } = usePagination({
     items: selectedTeams,
     itemsPerPage,
   })
   // for pagination

  const handleChange = event => {
    setSearch(event.target.value)
    var path = "/about-us/our-team"
    path += event.target.value ? "?name=" + event.target.value : ""
    
    path +=
      selectedOption.value && event.target.value !== ""
        ? "&department=" + selectedOption.value
        : selectedOption.value && event.target.value === ""
        ? "?department=" + selectedOption.value
        : ""
    navigate(path?.replace(" ","-"))
    let teamList = teamsData

    if (selectedOption.value !== "all" && event.target.value === "") {
      teamList = teamsData.filter(team => {
        if (team.node.team_member?.length > 0) {
          return team.node.team_member?.some(
            item =>
              item.designation?.replace(/ /g, "-").toLowerCase() ===
              selectedOption.value
          )
        }
      })
    }

    if (selectedOption.value !== "all" && event.target.value) {
      teamList = teamsData.filter(team => {
        if (team.node.team_member?.length > 0) {
          return team.node.team_member?.some(
            item =>
              item.designation?.replace(/ /g, "-").toLowerCase() ===
              selectedOption.value
          )
        }
      })
    }

    if (event.target.value) {
      // console.log(
      //   teamList.filter((team, index) => {
      //     // console.log(team)
      //     return team.node?.choose_areas?.some(c =>
      //       c.title?.toLowerCase().includes(event.target.value.toLowerCase())
      //     )
      //   })
      // )
      let teamMember = teamList.filter(
        (team, index) =>
          team.node?.name
            ?.toLowerCase()
            ?.includes(event.target.value.toLowerCase()) ||
          team.node?.choose_areas?.some(c =>
            c.title?.toLowerCase().includes(event.target.value?.toLowerCase())
          )
      )
      // console.log(teamMember)
      setSelectedTeams(teamMember)
    } else {
      setSelectedTeams(teamList)
    }
    setCurrentPage(1)
  }

  // const capitalFirst = text => {
  //   if (!text) return
  //   const words = text.split(" ")

  //   const result = words.map(word => word[0].toUpperCase() + word.substring(1))
  //   return result.join(" ")
  // }

  //setting searchedValue as default input
  // useEffect(() => {
  //   if (searchedValue !== "") {
  //     const optionValue = categoryOption.filter(option =>
  //       option.label?.toLowerCase()?.includes(searchedValue?.toLowerCase())
  //     )
  //     if (!optionValue?.length > 0) {
  //       setSearch(searchedValue)
  //     }
  //   }
  // }, [searchedValue])

  // useEffect(() => {
  //   if (searchedValue !== "") {
  //     const optionValue = categoryOption.filter(option =>
  //       option.label?.toLowerCase()?.includes(searchedValue?.toLowerCase())
  //     )
  //     if (optionValue?.length > 0) {
  //       setSelectedOption({
  //         label: capitalFirst(searchedValue),
  //         value: capitalFirst(searchedValue),
  //       })
  //     }
  //   }
  // }, [searchedValue])

 

  const handleTabChange = option => {
    // setSearch("")
    setSelectedOption(option)
    var path = "/about-us/our-team"
    path += search ? "?name=" + search?.replace(" ", "-") : ""
    path +=
      option?.value && search !== ""
        ? "&department=" + option?.value
        : option?.value && search === ""
        ? "?department=" + option?.value
        : ""

    navigate(path)
    if (option.value === "all") {
      let teamMember = teamsData.filter(
        (team, index) =>
          team.node?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          team.node?.choose_areas?.some(c =>
            c.title?.toLowerCase().includes(search?.toLowerCase())
          )
      )
      setSelectedTeams(search ? teamMember : teamsData)
      return
    }
    let filteredTeams = []
    teamsData.filter(team => {
      if (team.node.team_member?.length > 0) {
        team.node.team_member?.filter(member => {
          if (
            member?.designation
              ?.replace(/ /g, "-")
              .toLowerCase()
              ?.includes(option.value?.replace(/ /g, "-").toLowerCase())
          ) {
            filteredTeams.push(team)
          }
        })
      }
    })
    // console.log(
    //   filteredTeams.filter(
    //     (team, index) =>
    //       team.node?.name?.toLowerCase().slice(0, search.length) ===
    //         search.toLowerCase() ||
    //       team.node?.choose_areas?.some(c =>
    //         c.title?.toLowerCase().includes(search.toLowerCase())
    //       )
    //   )
    // )

    let teamMember = filteredTeams.filter(
      (team, index) =>
        team.node?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        team.node?.choose_areas?.some(c =>
          c.title?.toLowerCase().includes(search?.toLowerCase())
        )
    )

    setSelectedTeams(search ? teamMember : filteredTeams)
    setCurrentPage(1)
  }

  var imagename = "team.image.tile_image"

  return (
    <div className="team-listing-wrapper">
      <Container>
        <ScrollAnimation
          className="team-list-animation"
          animateIn="animate__slideInUp"
          animateOnce
          delay={150}
          offset={100}
        >
          <div className="team-sorting-section">
            <div className="search-box">
              <input
                className="form-control search"
                placeholder="Search by Name or Location"
                onChange={e => handleChange(e)}
                value={search}
              />
              <span>
                <i className="icon grey-search-icon" />
              </span>
            </div>

            <div className="select-department">
              <Select
                options={categoriesOptionList}
                isSearchable={false}
                className={"category-select"}
                classNamePrefix={"category-select"}
                //   hideSelectedOptions={true}
                value={selectedOption}
                onChange={e => handleTabChange(e)}
                components={{
                  DropdownIndicator: () => (
                    <i className="icon grey-down-arrow"></i>
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
              {/* <span>
              <i className="icon grey-down-arrow" />
            </span> */}
            </div>
          </div>
        </ScrollAnimation>
        <div className="team-listing-section">
          {search
            ? currentItems?.map((data, index) => {
                // let processedImages
                // processedImages =
                //   data?.node?.imagetransforms?.image_Transforms ||
                //   JSON.stringify({})
                let processedImages = JSON.stringify({})
                if (data?.node?.imagetransforms) {
                  processedImages =
                    data?.node?.imagetransforms?.image_Transforms
                }
                return (
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                  >
                    <div className="team-list" key={data.node?.slug}>
                      <div className="image-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <ImageModule
                            ImageSrc={data.node.image}
                            altText={`${data.node?.title}`}
                            imagetransforms={processedImages}
                            renderer="pic-src"
                            imagename={imagename}
                            strapi_id={data.node?.strapi_id}
                            classNames="img-fluid"
                          />
                          {/* <img src={data.node?.image?.url} alt={data.node?.title}/> */}
                        </Link>
                        <div className="image-overlay">
                          <Link to={`${teamURL}${data.node?.slug}/`}>
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content-section">
                        <Link to={`${teamURL}${data.node?.slug}`}>
                          <h5>{data.node.name}</h5>
                        </Link>
                        <p>{data.node.designation}</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                )
              })
            : currentItems?.map((data, index) => {
                // let processedImages
                // processedImages =
                //   data?.node?.imagetransforms?.image_Transforms ||
                //   JSON.stringify({})

                let processedImages = JSON.stringify({})
                if (data?.node?.imagetransforms) {
                  processedImages =
                    data?.node?.imagetransforms?.image_Transforms
                }

                return (
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                  >
                    <div className="team-list" key={data.node?.slug}>
                      <div className="image-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <ImageModule
                            ImageSrc={data.node?.image}
                            altText={`${data.node?.title}`}
                            imagetransforms={processedImages}
                            renderer="pic-src"
                            imagename={imagename}
                            strapi_id={data.node?.strapi_id}
                            classNames="img-fluid"
                          />
                          {/* <img src={data.node?.image?.url} alt={data.node?.title}/> */}
                        </Link>
                        <div className="image-overlay">
                          <Link to={`${teamURL}${data.node?.slug}/`}>
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <h5>{data.node?.name}</h5>
                        </Link>
                        <p>{data.node?.designation}</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                )
              })}
        </div>

        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={selectedTeams.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default TeamListing
