import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import TeamLandingBanner from "../components/TeamLandingBanner/TeamLandingBanner"
import TeamListing from "../components/TeamListing/TeamListing"
import TeamStaticCard from "../components/TeamStaticCard/TeamStaticCard"
import Layout from "../components/layout"
import SEO from "../components/seo"



const TeamLanding = ({ data }) => {
  const teamPageData = data.strapiPage
  const [shareIcons, setShareIcons] = useState(false)

  const sortTeam = data.allStrapiTeam?.edges.sort(
    (a, b) => parseInt(a.node.sort) - parseInt(b.node.sort)
  )
  const teamMember = sortTeam
  const teamCategories = data.allStrapiTeamCategorie?.edges

  const searchValue =
    typeof window !== "undefined" ? window.history?.state?.inputValue : ""

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  let popUpData=""

  if(teamPageData?.add_page_modules?.length>0){
     const results=teamPageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  // useEffect(() => {
  //   // Add an event listener to handle page change or refresh
  //   const handleBeforeUnload = () => {
  //     window.history.replaceState({}, "replace")

  //     window.history.pushState(null, "back", pageurl)
  //   }

  //   window.addEventListener("popstate", handleBeforeUnload)

  //   window.addEventListener("beforeunload", handleBeforeUnload)

  //   return () => {
  //     // Clean up the event listener when the component unmounts
  //     window.removeEventListener("beforeunload", handleBeforeUnload)
  //   }
  // }, [])

  return (
    <Layout
      popUpData={popUpData}
      dark={true}
      popularSearch={teamPageData?.select_popular_search?.title}
    >
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={teamPageData?.choose_menu}
            pagename={teamPageData?.title}
            type="share-button"
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>
      {teamPageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT" && (
              <TeamLandingBanner pageData={teamPageData} moduleData={item} />
            )}
          </div>
        )
      })}

      <TeamListing
        searchValue={searchValue}
        teamsData={teamMember}
        strapi_id={teamPageData?.strapi_id}
        teamCategories={teamCategories}
      />
      {teamPageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" && (
              <TeamStaticCard cardData={item?.cards} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"
      />
  )
}

export default TeamLanding

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              title
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          __typename
          id
          content {
            data {
              content
            }
          }
          title
        }
      }
    }

    allStrapiTeamCategorie {
      edges {
        node {
          designation
          slug
        }
      }
    }

    allStrapiTeam(filter: { publish: { eq: true } }) {
      edges {
        node {
          image {
            url
          }
          imagetransforms {
            image_Transforms
          }
          slug
          strapi_id
          designation
          name
          sort
          team_member {
            slug
            designation
          }
          choose_areas {
            title
            slug
          }
        }
      }
    }
  }
`
