import { Link, navigate } from "gatsby"
import React from "react"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./BreadcrumbModule.scss"

const BreadcrumbModule = props => {
  // const handleBack = () => {
  //   window?.history?.back()
  // }

  const { isMobile } = useDeviceMedia()

  const handleBack = props => {
    if (props.alias === "sales" && props.department === "residential") {
      navigate("/properties/for-sale/in-dubai/")
    } else if (
      props.alias === "lettings" &&
      props.department === "residential"
    ) {
      navigate("/properties/to-rent/in-dubai/")
    } else if (
      props.alias === "sales" &&
      props.department === "new_developments"
    ) {
      navigate("/off-plan-properties/for-sale/in-dubai/")
    } else if (props.alias === "sales" && props.department === "commercial") {
      navigate("/commercial-properties/for-sale/in-dubai/")
    } 
    else if (props.alias === "lettings" && props.department === "commercial") {
      navigate("/commercial-properties/to-rent/in-dubai/")
    }
  }
  // console.log(props?.choose_menu)
  var page = ""
  var subsubpage = ""
  var subsubtitle = ""
  var subpage = ""
  var subtitle = ""
  if (props?.choose_menu?.length > 0) {
    if (props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug) {
      subsubpage =
        "/" + props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug + "/"
      subsubtitle = props?.choose_menu[0]?.strapi_parent?.strapi_parent?.title
      subpage =
        "/" +
        props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug +
        "/" +
        props?.choose_menu[0]?.strapi_parent?.slug +
        "/"
      subtitle = props?.choose_menu[0]?.strapi_parent?.title
    } else if (props?.choose_menu[0]?.strapi_parent?.slug) {
      subpage = "/" + props?.choose_menu[0]?.strapi_parent?.slug + "/"
      subtitle = props?.choose_menu[0]?.strapi_parent?.title
    } else {
      page = "/" + props?.choose_menu[0].slug + "/"
    }
  }

  return (
    <div className="breadcrumb-module-wrapper">
      <Container
        className={`${
          props.type === "property-details-page" ||
          props.type === "details-page" ||
          props.type === "share-button"
            ? "details-container"
            : ""
        }`}
      >
        <Row>
          <Col>
            <Breadcrumb>
              {(props?.type === "property-details-page" ||
                props?.type === "commercial-details-page") && (
                <a
                  className="breadcrumb-item back-to-list"
                  onClick={() => handleBack(props)}
                >
                  <i className="icon grey-arrow-icon" />
                  <span>Back to Listings</span>
                  <div className="vert-line"></div>
                </a>
              )}
              {props.type === "property-details-page" && !isMobile && (
                <Link to="/" className="breadcrumb-item home">
                  <span>Home</span>
                </Link>
              )}
              {props.type !== "property-details-page" && (
                <Link to="/" className="breadcrumb-item  home">
                  <span className="landing">Home</span>
                </Link>
              )}
              {subsubpage && (
                <Link to={`${subsubpage}`} className="breadcrumb-item">
                  <span>{subsubtitle}</span>
                </Link>
              )}
              {subpage && (
                <Link to={`${subpage}`} className="breadcrumb-item">
                  <span>{subtitle}</span>
                </Link>
              )}
              {props?.mainparent && (
                <Link to={`/${props.mainparent}/`} className="breadcrumb-item">
                  <span>{props.mainparentname}</span>
                </Link>
              )}
              {props?.parent && (
                <Link to={`/${props.parent}/`} className="breadcrumb-item">
                  <span>{props.parentname}</span>
                </Link>
              )}
              {props?.subparent && (
                <Link to={`/${props.subparent}/`} className="breadcrumb-item">
                  {props.subparentname}
                </Link>
              )}

              {/* {props.type === "property-details-page" &&
              !isMobile&&(
              props?.department === "commercial" ? (
                <Link to={`/commercial/`} className="breadcrumb-item">
                  <span>Commercial</span>
                </Link>
              ) : props.type === "property-details-page" &&
                props?.department === "new_developments" ? (
                <Link to={`/new-homes/`} className="breadcrumb-item">
                  <span>New Homes</span>
                </Link>
              ) : props.type === "property-details-page" &&
                props?.alias === "sales" ? (
                <Link to={`/sales/`} className="breadcrumb-item">
                  <span>Sales</span>
                </Link>
              ) : props.type === "property-details-page" &&
                props?.alias === "lettings" ? (
                <Link to={`/lettings/`} className="breadcrumb-item">
                  <span>Lettings</span>
                </Link>
              ) : null
            )} */}

              {props.type === "property-details-page" && !isMobile && (
                <a
                  // to={`/properties/${props?.alias === "sales" ? 'for-sale' : 'to-rent'}`}
                  onClick={() => handleBack(props)}
                  onKeyPress={() => handleBack()}
                  href="javascript:void(0)"
                  variant="none"
                  className="breadcrumb-item"
                >
                  {props?.alias === "sales" &&
                  props.department === "new_developments" ? (
                    <span>Off Plan for Sale</span>
                  ) : props?.alias === "sales" &&
                    props.department === "residential" ? (
                    <span>Property for sale in Dubai</span>
                  ) : props?.alias === "sales" &&
                    props.department === "commercial" ? (
                    <span>Commercial property for sale in Dubai</span>
                  ) : props?.alias === "lettings" ? (
                    <span>Property to rent</span>
                  ) : (
                    ""
                  )}
                </a>
              )}
              {props.type === "property-details-page" && !isMobile && (
                <Breadcrumb.Item active>{props.pagename}</Breadcrumb.Item>
              )}
              {props.type !== "property-details-page" && (
                <Breadcrumb.Item active>{props.pagename}</Breadcrumb.Item>
              )}
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BreadcrumbModule
